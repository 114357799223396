import { required, requiredIf } from '@vuelidate/validators';
import { LabelOption } from './option';

export const INVOICE_TYPE = 'invoice';
export const DIRECT_TYPE = 'direct';
export const MONTHLY_BILLING = 'monthly';
export const PERIODIC_BILLING = 'periodic';

export class Project {
  public id: number;
  public name: string;
  public company_id: number | null;
  public company_name: string;
  public rate: number;
  public period: string | null;
  public period_start: string;
  public date_start: string;
  public date_end: string;
  public description: string;
  public archived: boolean;
  public options: LabelOption[];
  public type: string;

  constructor(data: Project) {
    this.id = data.id;
    this.name = data.name;
    this.company_id = data.company_id;
    this.company_name = data.company_name;
    this.type = data.type;
    this.rate = data.rate;
    this.period = data.period;
    this.period_start = data.period_start;
    this.date_start = data.date_start;
    this.date_end = data.date_end;
    this.options = data.options;
    this.description = data.description;
    this.archived = data.archived;
  }
}

export const projectValidations = {
  project: {
    name: {
      required,
    },
    company_id: {
      required,
    },
    date_start: {
      required,
    },
    type: {},
    description: { required },
    period: {
      required: requiredIf(function () {
        return arguments[1].type === INVOICE_TYPE;
      }),
    },
    period_start: {},
    rate: {
      required: requiredIf(function () {
        return arguments[1].type === INVOICE_TYPE;
      }),
    },
  },
};
