import { required } from '@vuelidate/validators';

const LINE_NAMES = [
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
];

export interface ExpenseData {
  id: number;
  user_id: number;
  number: string;
  date_created: string;
  date_created_f: string;
  lines: ExpenseLineData[];
}

export class Expense {
  public id: number;
  public user_id: number;
  public number: string;
  public date_created: string;
  public date_created_f: string;
  public lines: ExpenseLine[];

  constructor(data: ExpenseData) {
    this.id = data.id;
    this.user_id = data.user_id;
    this.number = data.number;
    this.date_created = data.date_created;
    this.date_created_f = data.date_created_f;
    this.lines = data.lines.map((line) => new ExpenseLine(line));
  }

  get description() {
    return this.lines[0].description;
  }

  get vat() {
    return this.lines[0].vat;
  }

  get total() {
    return this.lines.reduce(
      (total, line) => total + parseFloat(`${line.total}`),
      0,
    );
  }

  get vat_total() {
    return this.lines.reduce((total, line) => total + line.vat_amount, 0);
  }

  get sub_total() {
    return this.lines.reduce((total, line) => total + line.amount, 0);
  }

  get vat_percentage() {
    return this.vat * 100;
  }

  public removeLine(line: ExpenseLine) {
    const index = this.lines.indexOf(line);
    this.lines.splice(index, 1);

    // fix line names
    this.lines.forEach((l: ExpenseLine, i: number) => {
      l.name = LINE_NAMES[i];
    });
  }

  public addLine(data: Partial<ExpenseLineData>) {
    const name = LINE_NAMES[this.lines.length];
    this.lines.push(
      new ExpenseLine({ name, description: '', total: 0, vat: 0, ...data }),
    );
  }
}

interface ExpenseLineData {
  name: string;
  description: string;
  total: number;
  vat: number;
}

export class ExpenseLine {
  public name: string;
  public description: string;
  public total: number;
  public vat: number;

  constructor(data: ExpenseLineData) {
    this.name = data.name;
    this.description = data.description;
    this.total = data.total;
    this.vat = data.vat;
  }

  get vat_amount() {
    return this.total - this.amount;
  }

  set vat_amount(v: number) {
    //
  }

  get amount() {
    return (this.total / (1 + this.vat)) * 1;
  }
}

export const expenseValidations = {
  expense: {
    date_created: {
      required,
    },
  },
};

export const expenseLineValidations = {
  line: {
    name: {
      required,
    },
    description: {
      required,
    },
    vat: {
      required,
    },
    total: {
      required,
    },
  },
};
