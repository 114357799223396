import { DateUtil, MINUTES_IN_HOUR } from '@/core/util/date.util';
import { Holiday } from '@/models/holidays';

// save data
export interface Hours {
  date: string;
  id?: number;
  project_id?: number;
  hours?: string;
  minutes?: number;
  description?: string;
}

export const mapToHours = (day: TimesheetDay): Hours => {
  const { date, id, project_id, hours, minutes, description } = day;
  return { date, id, project_id, hours, minutes, description };
};

export interface TimesheetDayData extends Hours {
  day: number;
  week_number: number;
  today?: boolean;
  previous?: boolean;
  next?: boolean;
  holiday?: Holiday;
}

export class TimesheetDay {
  public day: number;
  public date: string;
  public week_number: number;

  // rendering
  public holiday?: Holiday;
  public today?: boolean = false;
  public previous?: boolean = false;
  public next?: boolean = false;

  // written hours
  public id?: number;
  public project_id?: number;
  public hours?: string;
  public minutes?: number;
  public description?: string;
  public staged: boolean = false;

  private state!: TimesheetDayData;

  constructor(data: TimesheetDayData) {
    // date information
    this.day = data.day;
    this.date = data.date;
    this.week_number = data.week_number;

    this.setData(data);
  }

  public setData(data: TimesheetDayData) {
    // date information
    this.day = data.day;
    this.date = data.date;
    this.week_number = data.week_number;

    // written hours
    this.id = data.id;
    this.project_id = data.project_id;
    this.hours = data.hours;
    this.minutes = data.minutes || 0;
    this.description = data.description;

    // rendering
    this.previous = data.previous ? data.previous : false;
    this.next = data.next ? data.next : false;
    this.today = data.today ? data.today : false;

    // holiday information
    this.holiday = data.holiday;
    this.setState();
  }

  public isValid() {
    return DateUtil.isValid(this.date) && this.day !== 0;
  }

  public isValidHours() {
    return (
      this.isValid() &&
      this.project_id !== -1 &&
      this.hours !== '' &&
      this.minutes &&
      this.minutes > 0
    );
  }

  public isChanged(): boolean {
    return (
      this.state.id !== this.data.id ||
      this.state.day !== this.data.day ||
      this.state.date !== this.data.date ||
      this.state.project_id !== this.data.project_id ||
      this.state.hours !== this.data.hours ||
      this.state.minutes !== this.data.minutes ||
      this.state.description !== this.data.description
    );
  }

  public stage() {
    this.staged = true;
    this.updateMinutes();
  }

  public unstage() {
    this.staged = false;
  }

  public isStaged(): boolean {
    return this.staged;
  }

  public updateMinutes() {
    if (this.hours) {
      const hours = parseFloat(this.hours) || 0;
      this.minutes = Math.ceil(hours * MINUTES_IN_HOUR);
    } else {
      this.minutes = 0;
    }
  }

  get data(): TimesheetDayData {
    const {
      id,
      day,
      date,
      project_id,
      hours,
      minutes,
      description,
      week_number,
    } = this;

    return {
      id,
      day,
      date,
      project_id,
      hours,
      minutes,
      description,
      week_number,
    };
  }

  public setState() {
    this.state = this.data;
  }
}
