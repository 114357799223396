export enum HolidayName {
  newyearsday = 'newyearsday',
  goodfriday = 'goodfriday',
  firsteasterday = 'firsteasterday',
  secondeasterday = 'secondeasterday',
  kingsday = 'kingsday',
  liberationday = 'liberationday',
  ascensionday = 'ascensionday',
  firstpentacostday = 'firstpentacostday',
  secondpentacostday = 'secondpentacostday',
  firstchristmasday = 'firstchristmasday',
  secondchristmasday = 'secondchristmasday',
}

export class Holidays {
  public year: number;
  public newyearsday!: Holiday;
  public goodfriday!: Holiday;
  public firsteasterday!: Holiday;
  public secondeasterday!: Holiday;
  public kingsday!: Holiday;
  public liberationday!: Holiday;
  public ascensionday!: Holiday;
  public firstpentacostday!: Holiday;
  public secondpentacostday!: Holiday;
  public firstchristmasday!: Holiday;
  public secondchristmasday!: Holiday;

  constructor(data: Holidays) {
    this.year = data.year;
    if (data.newyearsday) {
      this.newyearsday = new Holiday(data.newyearsday);
    }
    if (data.goodfriday) {
      this.goodfriday = new Holiday(data.goodfriday);
    }
    if (data.firsteasterday) {
      this.firsteasterday = new Holiday(data.firsteasterday);
    }
    if (data.secondeasterday) {
      this.secondeasterday = new Holiday(data.secondeasterday);
    }
    if (data.kingsday) {
      this.kingsday = new Holiday(data.kingsday);
    }
    if (data.liberationday) {
      this.liberationday = new Holiday(data.liberationday);
    }
    if (data.ascensionday) {
      this.ascensionday = new Holiday(data.ascensionday);
    }
    if (data.firstpentacostday) {
      this.firstpentacostday = new Holiday(data.firstpentacostday);
    }
    if (data.secondpentacostday) {
      this.secondpentacostday = new Holiday(data.secondpentacostday);
    }
    if (data.firstchristmasday) {
      this.firstchristmasday = new Holiday(data.firstchristmasday);
    }
    if (data.secondchristmasday) {
      this.secondchristmasday = new Holiday(data.secondchristmasday);
    }
  }

  public update(fields: any) {
    const getName = (key: string) =>
      fields.find((item: any) => item.name === key).label;

    this.newyearsday.name = getName(HolidayName.newyearsday);
    this.goodfriday.name = getName(HolidayName.goodfriday);
    this.firsteasterday.name = getName(HolidayName.firsteasterday);
    this.secondeasterday.name = getName(HolidayName.secondeasterday);
    this.kingsday.name = getName(HolidayName.kingsday);
    this.liberationday.name = getName(HolidayName.liberationday);
    this.ascensionday.name = getName(HolidayName.ascensionday);
    this.firstpentacostday.name = getName(HolidayName.firstpentacostday);
    this.secondpentacostday.name = getName(HolidayName.secondpentacostday);
    this.firstchristmasday.name = getName(HolidayName.firstchristmasday);
    this.secondchristmasday.name = getName(HolidayName.secondchristmasday);
  }

  public data() {
    return {
      year: this.year,
      newyearsday: this.newyearsday.data(),
      goodfriday: this.goodfriday.data(),
      firsteasterday: this.firsteasterday.data(),
      secondeasterday: this.secondeasterday.data(),
      kingsday: this.kingsday.data(),
      liberationday: this.liberationday.data(),
      ascensionday: this.ascensionday.data(),
      firstpentacostday: this.firstpentacostday.data(),
      secondpentacostday: this.secondpentacostday.data(),
      firstchristmasday: this.firstchristmasday.data(),
      secondchristmasday: this.secondchristmasday.data(),
    };
  }
}

interface HolidayData {
  name: string;
  date: string;
}

export class Holiday {
  public name: string = '';
  public date: string = '';

  constructor(data?: HolidayData) {
    if (data) {
      this.name = data.name;
      this.date = data.date;
    }
  }

  public data() {
    return this.date
      ? {
          name: this.name,
          date: this.date,
        }
      : null;
  }
}
