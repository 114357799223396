import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
} from 'vue-router';
import TestForm from '@/components/TestForm.vue';
import LoginForm from '@/components/LoginForm.vue';
import Profile from '@/components/user/Profile.vue';
import User from '@/components/user/User.vue';
import UserForm from '@/components/user/UserForm.vue';
import ProjectList from '@/components/project/ProjectList.vue';
import Project from '@/components/project/Project.vue';
import CompanyList from '@/components/company/CompanyList.vue';
import Company from '@/components/company/Company.vue';
import CompanyForm from '@/components/company/CompanyForm.vue';
import Balance from '@/components/balance/Balance.vue';
import ExpenseForm from '@/components/expense/ExpenseForm.vue';
import ExpensePage from '@/components/pages/ExpensePage.vue';
import InvoiceForm from '@/components/invoice/InvoiceForm.vue';
import InvoicePage from '@/components/pages/InvoicePage.vue';
import TaxBalance from '@/components/balance/TaxBalance.vue';
import Timesheet from '@/components/timesheet/Timesheet.vue';
import TimesheetOverview from '@/components/timesheet/overview/TimesheetOverview.vue';
import DayByDayOverview from '@/components/timesheet/overview/DayByDayOverview.vue';
import SystemPage from '@/components/system/SystemPage.vue';
import Holidays from '@/components/system/Holidays.vue';
import TimesheetPrint from '@/components/pages/TimesheetPrint.vue';

import { Actions, store } from './store';

const authenticate = async (
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: (r?: any) => any,
): Promise<void> => {
  // TODO: do not check auth api again, unless the session is older than x (server sets cookie at 3 hours)
  const auth = await store.dispatch(Actions.Auth);

  if (auth.isUser) {
    next();
    return;
  }
  next({
    path: '/login',
    props: { redirect: to.fullPath },
  });
};

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/timesheet',
      beforeEnter: authenticate,
      component: ProjectList,
    },
    {
      path: '/project/:id',
      beforeEnter: authenticate,
      component: Project,
    },
    {
      path: '/timesheet/project/:id',
      beforeEnter: authenticate,
      component: Timesheet,
    },
    {
      path: '/timesheet/project/:id/:date',
      beforeEnter: authenticate,
      component: Timesheet,
    },
    {
      path: '/timesheet/project/:id/:date/print',
      beforeEnter: authenticate,
      component: TimesheetPrint,
    },
    {
      path: '/timesheet/overview/day-by-day',
      beforeEnter: authenticate,
      component: DayByDayOverview,
    },
    {
      path: '/timesheet/overview/day-by-day/:date',
      beforeEnter: authenticate,
      component: DayByDayOverview,
    },
    {
      path: '/timesheet/overview',
      beforeEnter: authenticate,
      component: TimesheetOverview,
    },
    {
      path: '/timesheet/overview/:date',
      beforeEnter: authenticate,
      component: TimesheetOverview,
    },
    {
      path: '/profile',
      beforeEnter: authenticate,
      component: Profile,
    },
    {
      path: '/user/:id',
      beforeEnter: authenticate,
      component: User,
    },
    {
      path: '/user/:id/edit',
      beforeEnter: authenticate,
      component: UserForm,
    },
    {
      path: '/company',
      beforeEnter: authenticate,
      component: CompanyList,
    },
    {
      path: '/company/:id',
      beforeEnter: authenticate,
      component: Company,
    },
    {
      path: '/company/:id/edit',
      beforeEnter: authenticate,
      component: CompanyForm,
    },
    {
      path: '/balance',
      beforeEnter: authenticate,
      component: Balance,
    },
    {
      path: '/balance/:type/:date',
      beforeEnter: authenticate,
      component: Balance,
    },
    {
      path: '/balance/expense/new',
      beforeEnter: authenticate,
      component: ExpenseForm,
    },
    {
      path: '/balance/expense/:number',
      beforeEnter: authenticate,
      component: ExpensePage,
    },
    {
      path: '/balance/invoice/new/date/:date/project/:id/',
      beforeEnter: authenticate,
      component: InvoiceForm,
    },
    {
      path: '/balance/invoice/:number',
      beforeEnter: authenticate,
      component: InvoicePage,
    },
    {
      path: '/balance/tax/:type/:date',
      beforeEnter: authenticate,
      component: TaxBalance,
    },
    {
      path: '/system',
      beforeEnter: authenticate,
      component: SystemPage,
    },
    {
      path: '/system/holidays',
      beforeEnter: authenticate,
      component: Holidays,
    },
    {
      path: '/system/holidays/:year',
      beforeEnter: authenticate,
      component: Holidays,
    },
    {
      path: '/login',
      component: LoginForm,
    },
    {
      path: '/test',
      component: TestForm,
    },
    {
      path: '/*',
      redirect: '/timesheet',
      beforeEnter: authenticate,
    },
    {
      path: '/',
      redirect: '/timesheet',
      beforeEnter: authenticate,
    },
  ],
});
