import { Address } from '@/models/address';
import { required, email } from '@vuelidate/validators';
import { zipcode } from '@/core/validation';

export enum Level {
  Admin = 200,
  User = 100,
}

export interface UserModel {
  id: number;
  first_name: string;
  last_name: string;
  level: Level;
  phone: string;
  email: string;
  account: string;
  last_active: string;
  address: Address;
}

export class User {
  public id: number;
  public first_name: string;
  public last_name: string;
  public level: Level;
  public phone: string;
  public email: string;
  public account: string;
  public last_active: string;
  public address: Address;

  constructor(data: UserModel) {
    this.id = data.id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.level = data.level;
    this.phone = data.phone;
    this.email = data.email;
    this.account = data.account;
    this.last_active = data.last_active;
    this.address = new Address(data.address);
  }
}

export const userValidations = {
  email: {
    required,
    email,
  },
  first_name: {
    required,
  },
  last_name: {
    required,
  },
  account: {},
  phone: {
    required,
  },
  address: {
    name: {
      required,
    },
    line_one: {
      required,
    },
    line_two: {},
    zipcode: {
      required,
      zipcode,
    },
    city: {
      required,
    },
  },
};
