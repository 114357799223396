import { InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';
// models
import { LoginData, Auth } from '@/models/auth';
import { User } from '@/models/user';
import { Company } from '@/models/company';
import { Project } from '@/models/project';
import { ProjectInfo } from '@/models/project-info';
import {
  getNotificationId,
  getNotificationTimeout,
  Notification,
} from '@/models/notification';
// services
import authService from '@/services/auth.service';
import userService from '@/services/user.service';
import companyService from '@/services/company.service';
import projectService from '@/services/project.service';
import adminService from './services/admin.service';
import searchService from './services/search.service';
// modules
import { timesheetStore } from './stores/timesheet.store';
import { balanceStore } from './stores/balance.store';
import { drawerStore } from './stores/drawer.store';
import { modalStore } from './stores/modal.store';
import { Result } from './models/results.model';
import balanceService from './services/balance.service';
import { TaxInfo } from './models/taxes';

export interface DocsState {
  auth: Auth | null;
  user: User | null;
  profile: User | null;
  filter: string;
  companies: Company[];
  company: Company | null;
  companyProjects: Project[];
  projects: ProjectInfo[];
  project: Project | null;
  holidays: any;
  notifications: Notification[];
  results: Result<any>[];
}

export enum Mutations {
  SetAuth = 'SetAuth',
  SetUser = 'SetUser',
  SetProfile = 'SetProfile',
  UpdateUser = 'UpdateUser',
  UpdateFilter = 'UpdateFilter',
  SetCompanies = 'SetCompanies',
  SetCompany = 'SetCompany',
  SetCompanyProjects = 'SetCompanyProjects',
  UpdateCompany = 'UpdateCompany',
  SetProjects = 'SetProjects',
  SetProject = 'SetProject',
  UpdateProject = 'UpdateProject',
  SetHolidays = 'SetHolidays',
  ShowNotification = 'ShowNotification',
  DismissNotification = 'DismissNotification',
  SetSearchResults = 'SetSearchResults',
  ResetSearchResults = 'ResetSearchResults',
}

export enum Actions {
  Auth = 'Auth',
  Login = 'Login',
  Logout = 'Logout',
  GetProfile = 'GetProfile',
  UpdateProfile = 'UpdateProfile',
  GetUser = 'GetUser',
  GetCompanies = 'GetCompanies',
  GetCompany = 'GetCompany',
  NewCompany = 'NewCompany',
  StoreCompany = 'StoreCompany',
  RemoveCompany = 'RemoveCompany',
  GetCompanyProjects = 'GetCompanyProjects',
  GetProjects = 'GetProjects',
  NewProject = 'NewProject',
  StoreProject = 'StoreProject',
  GetProject = 'GetProject',
  RemoveProject = 'RemoveProject',
  GetHolidays = 'GetHolidays',
  StoreHolidays = 'StoreHolidays',
  ShowNotification = 'ShowNotification',
  Search = 'Search',
  CreateTaxes = 'CreateTaxes',
  UpdateTaxes = 'UpdateTaxes',
}

export const store = createStore<DocsState>({
  modules: {
    bs: balanceStore,
    ts: timesheetStore,
    ds: drawerStore,
    ms: modalStore,
  },
  state: {
    auth: null,
    user: null,
    profile: null,
    filter: '',
    companies: [],
    company: null,
    companyProjects: [],
    projects: [],
    project: null,
    holidays: undefined,
    notifications: [],
    results: [],
  },
  mutations: {
    [Mutations.SetAuth]: (state: DocsState, auth: Auth) => {
      state.auth = auth;
    },
    [Mutations.SetUser]: (state: DocsState, user) => {
      state.user = user;
    },
    [Mutations.SetProfile]: (state: DocsState, profile: User) => {
      state.profile = profile;
    },
    [Mutations.UpdateUser]: (state: DocsState, user) => {
      state.user = Object.assign(state.user || {}, user);
    },
    [Mutations.UpdateFilter]: (state: DocsState, filter: string) => {
      state.filter = filter;
    },
    [Mutations.SetCompanies]: (state: DocsState, companies: Company[]) => {
      state.companies = companies;
    },
    [Mutations.SetCompany]: (state: DocsState, company: Company) => {
      state.company = company;
    },
    [Mutations.SetCompanyProjects]: (state: DocsState, projects: Project[]) => {
      state.companyProjects = projects;
    },
    [Mutations.UpdateCompany]: (state: DocsState, company: Company) => {
      state.company = Object.assign(state.user || {}, company);
    },
    [Mutations.SetProjects]: (state: DocsState, projects: ProjectInfo[]) => {
      state.projects = projects;
    },
    [Mutations.SetProject]: (state: DocsState, project: Project) => {
      state.project = project;
    },
    [Mutations.UpdateProject]: (state: DocsState, project: Project) => {
      state.project = Object.assign(state.user || {}, project);
    },
    [Mutations.SetHolidays]: (state: DocsState, holidays) => {
      state.holidays = holidays;
    },
    [Mutations.ShowNotification]: (
      state: DocsState,
      notification: Notification,
    ) => {
      state.notifications = [...state.notifications, notification];
    },
    [Mutations.DismissNotification]: (state: DocsState, id: Number) => {
      state.notifications = [...state.notifications.filter((n) => n.id !== id)];
    },
    [Mutations.SetSearchResults]: (
      state: DocsState,
      results: Result<any>[],
    ) => {
      state.results = results;
    },
    [Mutations.ResetSearchResults]: (state: DocsState) => {
      state.results = [];
    },
  },
  actions: {
    async [Actions.Login](
      { commit, dispatch },
      data: LoginData,
    ): Promise<Auth | null> {
      const res = await authService.login(data);
      if (res && res.status) {
        commit(Mutations.SetAuth, res.data);
        await dispatch(Actions.GetProfile);
        dispatch(Actions.ShowNotification, {
          message: 'Login successful',
          type: 'info',
        });
        return dispatch(Actions.Auth);
      } else {
        return null;
      }
    },
    async [Actions.Logout]({ commit }) {
      await authService.logout();
      commit(Mutations.SetAuth, null);
    },
    async [Actions.Auth]({ commit, state }): Promise<Auth | null> {
      if (state.auth && !state.auth.isExpired()) {
        return state.auth;
      }
      const res = await authService.get();
      commit(Mutations.SetAuth, res.data);
      return state.auth;
    },
    async [Actions.GetUser]({ commit }, id: number): Promise<User> {
      const res = await userService.get(id);
      commit(Mutations.SetUser, res.data);
      return res.data;
    },
    async [Actions.GetProfile]({ commit, state }): Promise<User | undefined> {
      if (state.profile) {
        return state.profile;
      }
      const res = await userService.profile();
      if (res.data) {
        commit(Mutations.SetProfile, res.data);
      }
      return res.data;
    },
    async [Actions.UpdateProfile]({ commit }, profile) {
      const res = await userService.put(profile);
      if (res.status) {
        commit(Mutations.SetProfile, res.data);
      }
    },
    async [Actions.GetCompanies]({ commit }): Promise<Company[]> {
      const res = await companyService.getList();
      commit(Mutations.SetCompanies, res.data);
      return res.data;
    },
    async [Actions.GetCompany](
      { commit },
      id: number,
    ): Promise<Company | undefined> {
      const res = await companyService.get(id);
      commit(Mutations.SetCompany, res.data);
      return res.data;
    },
    [Actions.NewCompany]({ commit }) {
      const company = companyService.new();
      commit(Mutations.SetCompany, company);
    },
    async [Actions.StoreCompany]({ dispatch }, company) {
      dispatch(Actions.ShowNotification, {
        message: 'Saving company',
        type: 'info',
      });
      if (company.id === -1) {
        await companyService.post(company);
      } else {
        await companyService.put(company);
      }
      dispatch(Actions.ShowNotification, {
        message: 'Company saved',
        type: 'confirm',
      });
      return dispatch(Actions.GetProjects);
    },
    async [Actions.GetCompanyProjects](
      { commit },
      id: number,
    ): Promise<Project[] | undefined> {
      const res = await projectService.getByCompany(id);
      commit(Mutations.SetCompanyProjects, res.data);
      return res.data;
    },
    async [Actions.RemoveCompany]({ dispatch }, id: number) {
      await companyService.delete(id);
      dispatch(Actions.GetProjects);
      dispatch(Actions.GetCompanies);
    },
    async [Actions.GetProjects]({ commit }): Promise<ProjectInfo[]> {
      const res = await projectService.getList();
      commit(Mutations.SetProjects, res.data);
      return res.data;
    },
    async [Actions.NewProject]({ commit }) {
      const project = projectService.new();
      commit(Mutations.SetProject, project);
    },
    async [Actions.StoreProject]({ dispatch, commit }, project) {
      dispatch(Actions.ShowNotification, {
        message: 'Saving project',
        type: 'info',
      });

      let p: Project;
      if (project.id === null || project.id === -1) {
        const d = await projectService.post(project);
        p = d.data;
      } else {
        const d = await projectService.put(project);
        p = d.data;
      }
      commit(Mutations.SetProject, p);
      dispatch(Actions.ShowNotification, {
        message: 'Project saved',
        type: 'confirm',
      });
      return dispatch(Actions.GetProjects);
    },
    async [Actions.GetProject](
      { commit },
      id: number,
    ): Promise<Project | undefined> {
      const res = await projectService.get(id);
      commit(Mutations.SetProject, res.data);
      return res.data;
    },
    async [Actions.RemoveProject]({ dispatch }, id: number) {
      await projectService.delete(id);
      dispatch(Actions.GetProjects);
    },
    async [Actions.GetHolidays]({ commit }, year) {
      const res = await adminService.getHolidays(year);
      commit(Mutations.SetHolidays, res.data);
    },
    async [Actions.StoreHolidays](_, holidays) {
      return adminService.storeHolidays(holidays);
    },
    async [Actions.ShowNotification](
      { commit, state },
      notification: Pick<Notification, 'message' | 'type'>,
    ): Promise<Notification> {
      const id = getNotificationId(state.notifications);
      const timeout = getNotificationTimeout(notification.type);
      const n = { ...notification, id };

      commit(Mutations.ShowNotification, n);

      if (timeout) {
        setTimeout(() => commit(Mutations.DismissNotification, id), timeout);
      }

      return n;
    },
    async [Actions.Search](
      { commit, state },
      value: string,
    ): Promise<Result<any>[]> {
      const res = await searchService.search(value);

      commit(Mutations.SetSearchResults, res.data);

      return res.data;
    },
    async [Actions.CreateTaxes](
      { dispatch },
      value: TaxInfo,
    ): Promise<TaxInfo> {
      const res = await balanceService.createTaxInfo(value);
      return res.data;
    },
    async [Actions.UpdateTaxes](
      { dispatch },
      value: TaxInfo,
    ): Promise<TaxInfo> {
      const res = await balanceService.updateTaxInfo(value);
      return res.data;
    },
  },
  getters: {
    filteredProjects: (state: DocsState): ProjectInfo[] => {
      return state.projects.filter(
        (p) =>
          `${p.name.toLowerCase()} ${p.description.toLowerCase()}  ${p.company_name.toLowerCase()}`.indexOf(
            state.filter,
          ) !== -1,
      );
    },
    filteredCompanies: (state: DocsState): Company[] => {
      return state.companies.filter(
        (p) =>
          `${p.name.toLowerCase()} ${p.description.toLowerCase()}  ${p.contact.toLowerCase()}`.indexOf(
            state.filter,
          ) !== -1,
      );
    },
  },
});
