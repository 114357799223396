import { Expense } from './expense';
import { Invoice } from './invoice';
import { TaxInfo } from './taxes';

interface BalanceInfo {
  title: string;
  subtitle: string;
  previous: string;
  previous_name: string;
  next: string;
  next_name: string;
  is_year: boolean;
  is_month: boolean;
  is_quarter: boolean;
  year: string;
  quarter: string;
  month: string;
}

interface BalanceOverviewItem {
  without_vat: number;
  vat: number;
  total: number;
}

export interface BalanceOverview {
  incoming: BalanceOverviewItem;
  outgoing: BalanceOverviewItem;
  balance: BalanceOverviewItem;
}

export class Balance {
  public info: BalanceInfo;
  public overview: BalanceOverview;
  public expenses: Expense[];
  public invoices: Invoice[];
  public taxes: TaxInfo;

  constructor(data: Balance) {
    this.info = data.info;
    this.overview = data.overview;
    this.expenses = (data.expenses || []).map(
      (expense) => new Expense(expense),
    );
    this.invoices = (data.invoices || []).map(
      (invoice) => new Invoice(invoice),
    );
    this.taxes = data.taxes;
  }
}
