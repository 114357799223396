export interface I18NData {
  [index: string]: string;
}

export enum I18NLanguage {
  en = 'en',
  nl = 'nl',
}

export type I18NLanguages = {
  [key in I18NLanguage]: I18NData;
};

export const i18n: I18NLanguages = {
  nl: {
    account: 'rekening',
    agreed: 'Voor akkoord',
    amount: 'Aantal',
    amountCost: 'Bedrag',
    amountExcl: 'Bedrag (Excl.)',
    amountIncl: 'Bedrag (Incl.)',
    balance: 'Balans',
    bankAccount: 'Bankrekening',
    COC: 'KvK',
    date: 'Datum',
    description: 'Omschrijving',
    expense: 'Onkosten',
    expenseSpecification: 'Specificatie uitgaven',
    from: 'van',
    hours: 'Uren',
    incomeSpecification: 'Specificatie inkomsten',
    incoming: 'Inkomend',
    inNameOf: 'Ten name van',
    invoice: 'Factuur',
    invoiceDate: 'Factuurdatum',
    invoiceNumber_lc: 'factuurnummer',
    invoiceNumber: 'Factuurnummer',
    invoicePeriod: 'Factuurperiode',
    items: 'Aantal',
    locale: 'nl-nl',
    nameAndDate: 'Naam en datum',
    number: 'Nummer',
    outgoing: 'Uitgaand',
    overview: 'Overzicht',
    paymentInstruction_part1: 'U wordt verzocht deze factuur binnen',
    paymentInstruction_part2:
      'te voldoen volgens de onderstaande rekening. Gelieve bij betaling',
    paymentInstruction_part3: 'te vermelden.',
    paymentInstruction: 'Betaalinstructie',
    rate: 'Uurtarief',
    signature: 'Handtekening',
    subTotal: 'Subtotaal',
    tarif: 'Tarief',
    taxes: 'Belasting informatie',
    taxesNoInfo: 'Geen belasting informatie beschikbaar',
    taxes_box_1_income: 'Box 1 inkomen',
    taxes_box_1_taxes: 'Box 1 belastingen',
    taxes_box_2_income: 'Box 2 inkomen',
    taxes_box_2_taxes: 'Box 2 belastingen',
    taxes_box_3_income: 'Box 3 inkomen',
    taxes_box_3_taxes: 'Box 3 belastingen',
    taxes_national_insurance_premium: 'Premie volksverzekering ',
    taxes_taxcredits: 'Heffingskortingen',
    taxes_total: 'Totaal',
    timesheet: 'Uren',
    to: 'tot',
    total: 'Totaal',
    totalExclVAT: 'Totaal exclusief BTW',
    TotalInclVAT: 'Totaal inclusief BTW',
    ttao: 'T.a.v',
    VAT: 'BTW',
    VATID: 'BTW ID',
    VATnumber: 'BTW Nummer',
    VATPercentage: 'BTW %',
    VATTotal: 'BTW Totaal',
    withoutVAT: 'Zonder BTW',
    workedHours: 'Gewerkte uren',
  },
  en: {
    account: 'account',
    agreed: 'For your approval',
    amount: 'Amount',
    amountCost: 'Amount',
    amountExcl: 'Amount (Excl.)',
    amountIncl: 'Amount (Incl.)',
    balance: 'Balance',
    bankAccount: 'Bank account',
    COC: 'CoC',
    date: 'Date',
    description: 'Description',
    expense: 'Expense',
    expenseSpecification: 'Expense specification',
    from: 'from',
    hours: 'Hours',
    incomeSpecification: 'Income specification',
    incoming: 'Incoming',
    inNameOf: 'In name of',
    invoice: 'Invoice',
    invoiceDate: 'Invoice date',
    invoiceNumber_lc: 'invoice number',
    invoiceNumber: 'Invoice number',
    invoicePeriod: 'Invoice period',
    items: 'Amount',
    locale: 'en-us',
    nameAndDate: 'Name and date',
    number: 'Number',
    outgoing: 'Outgoing',
    overview: 'Tax overview',
    paymentInstruction_part1:
      'You are requested to complete this invoice within',
    paymentInstruction_part2: 'per the account details below. Please reference',
    paymentInstruction_part3: 'when paying.',
    paymentInstruction: 'Payment instruction',
    rate: 'Rate',
    signature: 'Signature',
    subTotal: 'Subtotal',
    tarif: 'Rate',
    taxes: 'Tax information',
    taxesNoInfo: 'No tax information available',
    taxes_box_1_income: 'Box 1 income',
    taxes_box_1_taxes: 'Box 1 taxes',
    taxes_box_2_income: 'Box 2 income',
    taxes_box_2_taxes: 'Box 2 taxes',
    taxes_box_3_income: 'Box 3 income',
    taxes_box_3_taxes: 'Box 3 taxes',
    taxes_national_insurance_premium: 'National insurance premium',
    taxes_taxcredits: 'Tax credits',
    taxes_total: 'Total',
    timesheet: 'Timesheet',
    to: 'to',
    total: 'Total',
    totalExclVAT: 'Total excluding VAT',
    TotalInclVAT: 'Total including CAT',
    ttao: 'T.t.a.o',
    VAT: 'VAT',
    VATID: 'VAT ID',
    VATnumber: 'VAT Number',
    VATPercentage: 'VAT %',
    VATTotal: 'VAT Total',
    withoutVAT: 'Without VAT',
    workedHours: 'Worked hours',
  },
};
