export interface LoginData {
  user_email: string;
  user_password: string;
}

export class Auth {
  public expires: string = new Date().toISOString();
  public isUser: boolean = false;
  public isAdmin: boolean = false;
  public last_active: string = '';

  constructor(data: Auth) {
    this.expires = data.expires;
    this.isUser = data.isUser;
    this.isAdmin = data.isAdmin;
    this.last_active = data.last_active;
  }

  public isExpired(): boolean {
    const expires = new Date(this.expires).getTime();

    // signal expired after two hours, to refresh the session
    return (expires - Date.now()) / 3600000 < 2;
  }
}
