import { Company } from '@/models/company';
import { ProjectInfo } from './project-info';
import { TimesheetDay, Hours, TimesheetDayData } from './timesheet-day';
import { MINUTES_IN_HOUR } from '@/core/util/date.util';
import { TimeUtil } from '@/core/util/time.util';

interface WeekTotalData {
  isWeek: boolean;
  week_number: number;
  week_total: number;
  week_all_total: number;
}

export class WeekTotal {
  public isWeek: boolean;
  public week_number: number;
  public week_total: number;
  public week_all_total: number;

  constructor(data: WeekTotalData) {
    this.isWeek = data.isWeek;
    this.week_number = data.week_number;
    this.week_total = data.week_total;
    this.week_all_total = data.week_all_total;
  }

  get date() {
    return `week-${this.week_number}`;
  }
}

interface TimesheetInfo {
  title: string;
  month: string;
  date_formatted: string;
  month_all_total: string;
  previous: string;
  previous_name: string;
  next: string;
  next_name: string;
  weekdays: string[];
  current: string;
}

export interface TimesheetOverviewInfo {
  month_name: string;
  year: number;
  month_total: string;
  year_direct_total: string;
  year_total: string;
  current: string;
  previous: string;
  previous_name: string;
  next: string;
  next_name: string;
}

export type Days = TimesheetDay | WeekTotal;

export interface TimesheetData {
  info: TimesheetInfo;
  project: ProjectInfo;
  company: Company;
  days: Days[];
}

export const isTimesheetDay = (
  day: TimesheetDay | WeekTotal,
): day is TimesheetDay => (day as TimesheetDay).day !== undefined;

export const isWeekTotal = (day: TimesheetDay | WeekTotal): day is WeekTotal =>
  (day as WeekTotal).isWeek;

export class Timesheet {
  public info: TimesheetInfo;
  public project: ProjectInfo;
  public company: Company;
  public days: Days[];

  constructor(data: TimesheetData) {
    this.info = data.info;
    this.project = new ProjectInfo(data.project);
    this.company = new Company(data.company);
    this.days = data.days.map((d: any) =>
      d.isWeek ? new WeekTotal(d) : new TimesheetDay(d),
    );
  }

  get monthTotal() {
    const days = this.days.filter((d) => isTimesheetDay(d)) as TimesheetDay[];
    const total = days
      .filter((d) => !d.previous && !d.next && d.minutes)
      .reduce((t, d) => t + (d.minutes || 0), 0);

    return total / MINUTES_IN_HOUR;
  }

  public updateDays(changes: Hours[], removals: Hours[]): Days[] {
    const days = [...this.days].reduce((acc: Days[], day: Days): Days[] => {
      if (isTimesheetDay(day)) {
        const change = changes.find((ch) => ch.date === day.date);
        const removal = removals.find((re) => re.date === day.date);

        const update: TimesheetDayData = {
          ...day,
        };

        if (change) {
          update.id = change.id;
          update.hours = change.hours;
          update.minutes = change.hours
            ? TimeUtil.decimalToMinutes(parseFloat(change.hours))
            : 0;
          update.description = change.description;
        } else if (removal) {
          update.id = undefined;
          update.hours = undefined;
          update.minutes = 0;
          update.description = '';
        }

        return [...acc, new TimesheetDay(update)] as Days[];
      } else {
        return [...acc, day] as Days[];
      }
    }, []);
    this.days = days;
    return days;
  }
}

export class ProjectOverview {
  public id: number;
  public name: string;
  public hours: Hours[];
  constructor(data: ProjectOverview) {
    this.id = data.id;
    this.name = data.name;
    this.hours = data.hours;
  }
}

export class TimesheetOverview {
  public info: TimesheetOverviewInfo;
  public month_data: ProjectOverview[];
  public year_data: ProjectOverview[];

  constructor({ info, month_data = [], year_data = [] }: TimesheetOverview) {
    this.info = info;
    this.month_data = month_data.map((data) => new ProjectOverview(data));
    this.year_data = year_data.map((data) => new ProjectOverview(data));
  }
}

export class DayHours {
  public id: number;
  public project_id: number;
  public project_name: string;
  public user_id: number;
  public date: string;
  public format_date: string;
  public hours: string;
  public description: string;

  constructor(data: DayHours) {
    this.id = data.id;
    this.project_id = data.project_id;
    this.project_name = data.project_name;
    this.user_id = data.user_id;
    this.date = data.date;
    this.format_date = data.format_date;
    this.hours = data.hours;
    this.description = data.description;
  }
}

export class DayByDayOverview {
  public info: TimesheetOverviewInfo;
  public hours: DayHours[];
  constructor({ info, hours = [] }: DayByDayOverview) {
    this.info = info;
    this.hours = hours.map((data) => new DayHours(data));
  }
}

export class DayOverview {
  public date: string;
  public hours: DayHours[];

  constructor(data: DayOverview) {
    this.date = data.date;
    this.hours = data.hours.map((d) => new DayHours(d));
  }

  get total(): string {
    return TimeUtil.minutesToTime(
      this.hours.reduce((total: number, hour) => {
        total += TimeUtil.decimalToMinutes(parseFloat(hour.hours));
        return total;
      }, 0),
    );
  }
}
