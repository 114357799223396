import {
  BaseService,
  Status,
  DataResponse,
  MessageResponse,
  MessageData,
} from '@/core/services/base.service';
import { Address } from '@/models/address';
import { Company } from '@/models/company';

class CompanyService extends BaseService {
  private url: string = '/api/company';

  private cached: Company[] = [];

  constructor() {
    super();
  }

  public new(): Company {
    return new Company({
      id: -1,
      name: '',
      label: '',
      contact: '',
      description: '',
      address: new Address({
        name: '',
        line_one: '',
        line_two: '',
        zipcode: '',
        city: '',
        country: '',
      }),
    });
  }

  public async getList(): Promise<DataResponse<Company[]>> {
    let res: any = {
      status: Status.Ok,
      data: [],
    };
    if (!this.hasCache()) {
      res = await this.$http.get<Company[]>(`${this.url}`);
      this.setCache(res.data.map((d: Company) => new Company(d)));
    }
    return {
      status: res.status === Status.Ok,
      data: this.getCache(),
    };
  }

  public async get(id: number): Promise<DataResponse<Company>> {
    const res = await this.$http.get<Company>(`${this.url}/${id}`);
    return {
      status: res.status === Status.Ok,
      data: new Company(res.data),
    };
  }

  public async put(company: Company): Promise<DataResponse<Company>> {
    const res = await this.$http.put<Company>(
      `${this.url}/${company.id}`,
      company,
    );
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Company(res.data),
    };
  }

  public async post(company: Company): Promise<DataResponse<Company>> {
    const res = await this.$http.post<Company>(this.url, company);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Company(res.data),
    };
  }

  public async delete(id: number): Promise<MessageResponse> {
    const res = await this.$http.delete<MessageData>(`${this.url}/${id}`);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      message: res.data.message,
    };
  }

  private getCache(): Company[] {
    return this.cached;
  }

  private setCache(list: Company[]): void {
    this.cached = list;
  }

  private hasCache(): boolean {
    return this.cached.length !== 0;
  }

  private clearCache(): void {
    this.setCache([]);
  }
}

export default new CompanyService();
