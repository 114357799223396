import { ValidationArgs } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const LINE_NAMES = [
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
];

export enum InvoiceType {
  Hours = 'hours',
  Items = 'items',
}

export interface InvoiceOption {
  label: string;
  value: string;
}

export interface InvoiceData {
  id: number;
  project_id: number;
  company_id: number;
  date_reference: string;
  number: string;
  subject: string;
  date_created: string;
  date_created_f?: string;
  date_paid?: string;
  options: InvoiceOption[];
  lines: InvoiceLineData[];
  paid: boolean;
  type: InvoiceType;
  language: string;
}

export class Invoice {
  public id: number;
  public project_id: number;
  public company_id: number;
  public date_reference: string;
  public number: string;
  public subject: string;
  public date_created: string;
  public date_created_f?: string;
  public date_paid?: string;
  public options: InvoiceOption[];
  public lines: InvoiceLine[];
  public paid: boolean;
  public type: InvoiceType;
  public language: string;

  constructor(data: InvoiceData) {
    this.id = data.id;
    this.project_id = data.project_id;
    this.company_id = data.company_id;
    this.date_reference = data.date_reference;
    this.number = data.number;
    this.subject = data.subject;
    this.date_created = data.date_created;
    this.date_created_f = data.date_created_f;
    this.date_paid = data.date_paid;
    this.options = data.options;
    this.lines = Object.entries(data.lines)
      .map((line) => ({
        name: line[0],
        ...(line[1] as InvoiceLineData),
      }))
      .filter((line) => line.total);
    this.paid = data.paid || false;
    this.type = data.type || InvoiceType.Hours;
    this.language = data.language;
  }

  get year() {
    return `${new Date().getFullYear()}`.substring(2);
  }

  get sub_total() {
    return this.lines.reduce((total, line) => total + line.total, 0);
  }

  get vat_total() {
    return this.lines.reduce((total, line) => total + line.total * line.vat, 0);
  }

  get total() {
    return this.lines.reduce(
      (total, line) => total + line.total + line.total * line.vat,
      0,
    );
  }

  get description() {
    return this.lines[0].description;
  }

  get expired() {
    const d = new Date(this.date_created);
    d.setMonth(d.getMonth() + 1);
    return d.getTime() < Date.now();
  }

  get invoiceNumber() {
    return this.number ? this.number : `${this.year}-𝖷𝖷𝖷𝖷`;
  }

  public removeLine(line: InvoiceLine) {
    const index = this.lines.indexOf(line);
    this.lines.splice(index, 1);

    // fix line names
    this.lines.forEach((l: InvoiceLine, i: number) => {
      l.name = LINE_NAMES[i];
    });
  }

  public addLine() {
    const name = LINE_NAMES[this.lines.length];
    this.lines.push({
      name,
      description: '',
      amount: 0,
      price: 0,
      vat: 0.21,
      total: 0,
    });
  }

  public removeOption(option: InvoiceOption) {
    const index = this.options.findIndex((o) => o.value === option.value);
    this.options.splice(index, 1);
  }

  public addOption() {
    this.options.push({
      label: '',
      value: '',
    });
  }
}

interface InvoiceLineData {
  name?: string;
  description: string;
  amount: number;
  price: number;
  vat: number;
  total: number;
}

export interface InvoiceLine {
  name: string;
  description: string;
  amount: number;
  price: number;
  vat: number;
  total: number;
}

export const invoiceValidations: ValidationArgs = {
  subject: {
    required,
  },
};

export const invoiceOptionsValidations: ValidationArgs = {
  label: {
    required,
  },
  value: {
    required,
  },
};

export const invoiceLineValidations: ValidationArgs = {
  description: {
    required,
  },
  amount: {
    required,
  },
  price: {
    required,
  },
};
