import { integer, required } from '@vuelidate/validators';
import { format } from 'date-fns';

export interface TaxInfo {
  id: number;
  year: number;
  data: TaxData[];
}

export interface TaxData {
  id: number;
  date_received: string;
  box_1_income: number;
  box_1_taxes: number;
  box_2_income: number;
  box_2_taxes: number;
  box_3_income: number;
  box_3_taxes: number;
  national_insurance_premium: number;
  tax_credits: number;
  preliminary: boolean;
}

export const getNewTaxData = (): TaxData => {
  return {
    id: 0,
    date_received: format(new Date(), 'yyyy-MM-dd'),
    box_1_income: 0,
    box_1_taxes: 0,
    box_2_income: 0,
    box_2_taxes: 0,
    box_3_income: 0,
    box_3_taxes: 0,
    national_insurance_premium: 0,
    tax_credits: 0,
    preliminary: true,
  };
};

export const taxValidations = {
  year: {
    required,
    integer,
  },
};

export const taxDataValidations = {
  date_received: {
    required,
  },
  box_1_income: {
    required,
  },
  box_1_taxes: {
    required,
  },
  box_2_income: {},
  box_2_taxes: {},
  box_3_income: {},
  box_3_taxes: {},
  national_insurance_premium: {
    required,
  },
  tax_credits: {
    required,
  },
  preliminary: {
    required,
  },
};
