import { Validation, ValidationArgs } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export interface ExpenseReference {
  user_id: number;
  expense_id: number;
  date_added: string;
  date_received: string;
  date_received_f: string;
  date_due: string;
  path: string;
  filename: string;
  size: number;
  summary: string;
}

export interface ExpenseReferenceInput {
  summary: string;
  file: File | null;
}

export interface HoursReference {
  project_id: number;
  date_added: string;
  date_received: string;
  date_start: string;
  date_end: string;
  path: string;
  filename: string;
  size: number;
  summary: string;
}

export const expenseReferenceValidations = {
  reference: {
    file: {
      required,
    },
    summary: {
      required,
    },
  },
};
