import timesheetService from '@/services/timesheet.service';
import { Module } from 'vuex';
import { DocsState } from '@/store';
import { TimeUtil } from '@/core/util/time.util';
import {
  Timesheet,
  TimesheetOverview,
  DayByDayOverview,
  DayOverview,
  Days,
} from '@/models/timesheet';
import { Invoice } from '@/models/invoice';
import { TimesheetDay, Hours, mapToHours } from '@/models/timesheet-day';

export interface TimesheetStore {
  timesheet: Timesheet;
  timesheet_days: Days[];
  timesheet_invoices: Invoice[];
  changedDays: TimesheetDay[];
  removedDays: TimesheetDay[];
  overview: TimesheetOverview | null;
  overview_by_day: DayByDayOverview | null;
  day_overview: DayOverview | null;
  project_hours: string;
}

export enum TimesheetMutations {
  SetTimesheet = 'ts/SetTimesheet',
  ResetTimesheetDays = 'ts/ResetTimesheetDays',
  UpdateTimesheetDays = 'ts/UpdateTimesheetDays',
  SetOverview = 'ts/SetOverview',
  SetDayByDayOverview = 'ts/SetDayByDayOverview',
  SetProjectHours = 'ts/SetProjectHours',
  SetDayOverview = 'ts/SetDayOverview',
  ResetStagedDay = 'ts/ResetStagedDay',
  UpdateChangedDays = 'ts/UpdateChangedDays',
  UpdateRemovedDays = 'ts/UpdateRemovedDays',
  ResetChangedDays = 'ts/ResetChangedDays',
  ResetRemovedDays = 'ts/ResetRemovedDays',
  SetTimesheetInvoices = 'ts/SetTimesheetInvoices',
}

export enum TimesheetActions {
  GetTimesheet = 'ts/GetTimesheet',
  UpdateTimesheet = 'ts/UpdateTimesheet',
  GetTimesheetOverview = 'ts/GetTimesheetOverview',
  GetDayByDayOverview = 'ts/GetDayByDayOverview',
  GetDayOverview = 'ts/GetDayOverview',
  GetProjectHours = 'ts/GetProjectHours',
  GetTimesheetInvoices = 'ts/GetTimesheetInvoices',
}

const sn = (str: TimesheetActions | TimesheetMutations): string =>
  str.replace(/ts\//g, '');

export const timesheetStore: Module<TimesheetStore, DocsState> = {
  namespaced: true,
  state: {
    timesheet: {} as Timesheet,
    timesheet_days: [],
    timesheet_invoices: [],
    changedDays: [],
    removedDays: [],
    overview: null,
    overview_by_day: null,
    day_overview: null,
    project_hours: '0',
  },
  mutations: {
    [sn(TimesheetMutations.SetTimesheet)](state, timesheet: Timesheet) {
      state.timesheet = timesheet;
      state.timesheet_days = timesheet.days;
    },
    [sn(TimesheetMutations.ResetTimesheetDays)](state) {
      state.timesheet_days = [];
    },
    [sn(TimesheetMutations.UpdateTimesheetDays)](state, changes: Hours[]) {
      state.timesheet_days = state.timesheet.updateDays(
        changes,
        state.removedDays.map(mapToHours),
      );
    },
    [sn(TimesheetMutations.SetOverview)](state, overview: TimesheetOverview) {
      state.overview = overview;
    },
    [sn(TimesheetMutations.SetDayByDayOverview)](state, overview) {
      state.overview_by_day = overview;
    },
    [sn(TimesheetMutations.SetProjectHours)](state, hours: string) {
      state.project_hours = TimeUtil.minutesToTimeSimple(
        TimeUtil.decimalToMinutes(parseFloat(hours)),
      );
    },
    [sn(TimesheetMutations.SetDayOverview)](state, overview) {
      state.day_overview = overview;
    },
    [sn(TimesheetMutations.ResetStagedDay)](state, day) {
      const changeIndex = state.changedDays.findIndex(
        (d: any) => d.date === day.date,
      );
      const removeIndex = state.removedDays.findIndex(
        (d: any) => d.date === day.date,
      );

      if (changeIndex > -1) {
        state.changedDays.splice(changeIndex, 1);
      }
      if (removeIndex > -1) {
        state.removedDays.splice(removeIndex, 1);
      }
    },
    [sn(TimesheetMutations.UpdateChangedDays)](state, day: TimesheetDay) {
      state.changedDays.push(day);
    },
    [sn(TimesheetMutations.UpdateRemovedDays)](state, day: TimesheetDay) {
      state.removedDays.push(day);
    },
    [sn(TimesheetMutations.ResetChangedDays)](state) {
      state.changedDays = [];
    },
    [sn(TimesheetMutations.ResetRemovedDays)](state) {
      state.removedDays = [];
    },
    [sn(TimesheetMutations.SetTimesheetInvoices)](state, invoices) {
      state.timesheet_invoices = invoices;
    },
  },
  actions: {
    async [sn(TimesheetActions.GetTimesheet)]({ commit }, { id, date }) {
      const res = await timesheetService.get(id, date);
      if (res.status) {
        commit(sn(TimesheetMutations.SetTimesheet), res.data);
      }
    },
    async [sn(TimesheetActions.UpdateTimesheet)]({ state, commit }) {
      const res = await timesheetService.save(
        state.changedDays.map(mapToHours),
        state.removedDays.map(mapToHours),
      );
      commit(sn(TimesheetMutations.UpdateTimesheetDays), res.data);
      commit(sn(TimesheetMutations.ResetChangedDays));
      commit(sn(TimesheetMutations.ResetRemovedDays));
    },
    async [sn(TimesheetActions.GetTimesheetOverview)]({ commit }, { date }) {
      const res = await timesheetService.getOverview(date);
      if (res.status) {
        commit(sn(TimesheetMutations.SetOverview), res.data);
      }
    },
    async [sn(TimesheetActions.GetDayByDayOverview)]({ commit }, { date }) {
      const res = await timesheetService.getDayByDayOverview(date);
      if (res.status) {
        commit(sn(TimesheetMutations.SetDayByDayOverview), res.data);
      }
    },
    async [sn(TimesheetActions.GetDayOverview)]({ commit }, date) {
      const res = await timesheetService.getDayOverview(date);
      if (res.status) {
        commit(sn(TimesheetMutations.SetDayOverview), res.data);
      }
    },
    async [sn(TimesheetActions.GetProjectHours)]({ commit }, projectId) {
      const res = await timesheetService.getProjectHours(projectId);
      if (res.status) {
        commit(sn(TimesheetMutations.SetProjectHours), res.data.hours);
      }
    },
    async [sn(TimesheetActions.GetTimesheetInvoices)](
      { commit },
      { id, date },
    ) {
      const res = await timesheetService.getTimesheetInvoices(id, date);
      commit(sn(TimesheetMutations.SetTimesheetInvoices), res.data);
    },
  },
  getters: {
    timesheet_hours: (state): TimesheetDay[] => {
      return state.timesheet_days.reduce((days: any, day: any) => {
        const change = state.changedDays.find((d: any) => d.date === day.date);
        const removed = state.removedDays.find((d: any) => d.date === day.date);

        if (change) {
          days.push(change);
        } else if (!removed) {
          days.push(day);
        }

        return days;
      }, []);
    },
    timesheet_weeks: (state) => {
      const first_day = state.timesheet_days.find(
        (day: any) => !day.next && !day.previous,
      );

      return state.timesheet_days
        .filter((day: any) => !day.next && !day.previous)
        .reduce(
          (weeks: any, day: any) => {
            let week = weeks[weeks.length - 1];

            if (day.isWeek) {
              week = {
                minutes: 0,
                start: null,
                week_number: day.week_number,
              };
              weeks.push(week);
            } else {
              week.start = !week.start ? day.date : week.start;
              week.end = day.date;
              week.minutes += day.minutes;
            }

            return weeks;
          },
          [
            {
              minutes: 0,
              start: null,
              week_number: first_day ? first_day.week_number : 0,
            },
          ],
        )
        .filter((week: any) => week.minutes !== 0);
    },
  },
};
