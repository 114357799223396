import { LabelOption } from './option';

export class ProjectInfo {
  public id: number;
  public name: string;
  public company_id: number;
  public company_name: string;
  public rate: number;
  public started: string;
  public ended: string;
  public description: string;
  public archived: boolean;
  public options: LabelOption[];
  public type: string;

  constructor(data: ProjectInfo) {
    this.id = data.id;
    this.name = data.name;
    this.company_id = data.company_id;
    this.company_name = data.company_name;
    this.rate = data.rate;
    this.started = data.started;
    this.ended = data.ended;
    this.description = data.description;
    this.archived = data.archived;
    this.options = data.options;
    this.type = data.type;
  }
}
