import { Module } from 'vuex';
import balanceService, {
  InvoiceInfo,
  ExpenseInfo,
} from '@/services/balance.service';
import { DocsState } from '@/store';
import { Balance } from '../models/balance';
import { Invoice } from '@/models/invoice';

export interface BalanceStore {
  balance: Balance | null;
  invoices: Invoice[];
  invoiceInfo: InvoiceInfo | null;
  expenseInfo: ExpenseInfo | null;
}

export enum BalanceMutations {
  SetBalance = 'bs/SetBalance',
  SetInvoices = 'bs/SetInvoices',
  SetInvoiceInfo = 'bs/SetInvoiceInfo',
  SetExpenseInfo = 'bs/SetExpenseInfo',
}

export enum BalanceActions {
  GetBalance = 'bs/GetBalance',
  AddInvoice = 'bs/AddInvoice',
  GetInvoice = 'bs/GetInvoice',
  GetProjectInvoices = 'bs/GetProjectInvoices',
  AddExpense = 'bs/AddExpense',
  GetExpense = 'bs/GetExpense',
  UpdateInvoiceStatus = 'bs/UpdateInvoiceStatus',
}

const sn = (str: BalanceActions | BalanceMutations): string =>
  str.replace(/bs\//g, '');

export const balanceStore: Module<BalanceStore, DocsState> = {
  namespaced: true,
  state: {
    balance: null,
    invoices: [],
    invoiceInfo: null,
    expenseInfo: null,
  },
  mutations: {
    [sn(BalanceMutations.SetBalance)](state, balance: Balance) {
      state.balance = balance;
    },
    [sn(BalanceMutations.SetInvoiceInfo)](state, info: InvoiceInfo) {
      state.invoiceInfo = info;
    },
    [sn(BalanceMutations.SetInvoices)](state, invoices: Invoice[]) {
      state.invoices = invoices;
    },
    [sn(BalanceMutations.SetExpenseInfo)](state, info: ExpenseInfo) {
      state.expenseInfo = info;
    },
  },
  actions: {
    async [sn(BalanceActions.GetBalance)]({ commit }, { type, date }) {
      const res = await balanceService.get(type, date);
      if (res.status) {
        commit(sn(BalanceMutations.SetBalance), res.data);
      }
    },
    async [sn(BalanceActions.AddInvoice)]({}, invoice) {
      const res = await balanceService.addInvoice(invoice);
      return res;
    },
    async [sn(BalanceActions.GetInvoice)]({ commit }, { number, date }) {
      const res = await balanceService.getInvoiceInfo(number, date);
      if (res.status) {
        commit(sn(BalanceMutations.SetInvoiceInfo), res.data);
      }
    },
    async [sn(BalanceActions.GetProjectInvoices)]({ commit }, { projectId }) {
      const res = await balanceService.getProjectInvoices(projectId);
      if (res.status) {
        commit(sn(BalanceMutations.SetInvoices), res.data);
      }
    },
    async [sn(BalanceActions.AddExpense)]({}, data): Promise<ExpenseInfo> {
      const res = await balanceService.addExpense(data);
      return res.data;
    },
    async [sn(BalanceActions.GetExpense)]({ commit }, { number, date }) {
      const res = await balanceService.getExpenseInfo(number, date);
      if (res.status) {
        commit(sn(BalanceMutations.SetExpenseInfo), res.data);
      }
    },
    async [sn(BalanceActions.UpdateInvoiceStatus)]({}, { number, date }) {
      await balanceService.updateInvoiceStatus(number, date);
    },
  },
};
