import {
  BaseService,
  Status,
  DataResponse,
  MessageData,
  MessageResponse,
} from '@/core/services/base.service';
import { Project } from '@/models/project';
import { ProjectInfo } from '@/models/project-info';

class ProjectService extends BaseService {
  private url: string = '/api/project';
  private cached: ProjectInfo[] = [];

  constructor() {
    super();
  }

  public new(): Project {
    return new Project({
      id: -1,
      name: '',
      company_id: null,
      company_name: '',
      type: '',
      rate: 0,
      period: '',
      period_start: '',
      date_start: '',
      date_end: '',
      options: [],
      description: '',
      archived: false,
    });
  }

  public async getList(): Promise<DataResponse<ProjectInfo[]>> {
    let res: any = {
      status: Status.Ok,
      data: [],
    };
    if (!this.hasCache()) {
      res = await this.$http.get<ProjectInfo[]>(`${this.url}/info`);
      this.setCache(
        res.data
          .map((item: ProjectInfo) => new ProjectInfo(item))
          .sort((a: ProjectInfo, b: ProjectInfo) => {
            if (a.ended === b.ended) {
              return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
            } else if (a.ended && !b.ended) {
              return 1;
            } else if (b.ended && !a.ended) {
              return -1;
            }
            return 0;
          }),
      );
    }
    return {
      status: res.status === Status.Ok,
      data: this.getCache(),
    };
  }

  public async getByCompany(id: number): Promise<DataResponse<Project[]>> {
    const res = await this.$http.get<Project[]>(`${this.url}/company/${id}`);
    return {
      status: res.status === Status.Ok,
      data: res.data.map((item) => new Project(item)),
    };
  }

  public async get(id: number): Promise<DataResponse<Project>> {
    const res = await this.$http.get<Project>(`${this.url}/${id}`);
    return {
      status: res.status === Status.Ok,
      data: new Project(res.data),
    };
  }

  public async put(project: Project): Promise<DataResponse<Project>> {
    const res = await this.$http.put<Project>(
      `${this.url}/${project.id}`,
      project,
    );
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Project(res.data),
    };
  }

  public async post(project: Project): Promise<DataResponse<Project>> {
    const res = await this.$http.post<Project>(this.url, project);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: new Project(res.data),
    };
  }

  public async delete(id: number): Promise<MessageResponse> {
    const res = await this.$http.delete<MessageData>(`${this.url}/${id}`);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      message: res.data.message,
    };
  }

  private getCache(): ProjectInfo[] {
    return this.cached;
  }

  private setCache(list: ProjectInfo[]): void {
    this.cached = list;
  }

  private hasCache(): boolean {
    return this.cached.length !== 0;
  }

  private clearCache(): void {
    this.setCache([]);
  }
}

export default new ProjectService();
