import { required } from '@vuelidate/validators';
import { Address } from './address';
import { Project } from './project';
import { zipcode } from '@/core/validation';

export class Company {
  public id: number;
  public name: string;
  public label: string;
  public contact: string;
  public description: string;
  public projects?: Project[] = [];
  public address: Address;

  constructor(data: Company) {
    this.id = data.id;
    this.name = data.name;
    this.label = data.label;
    this.contact = data.contact;
    this.description = data.description;
    this.projects = data.projects
      ? data.projects.map((d: Project) => new Project(d))
      : [];
    this.address = new Address(data.address);
  }
}

export const companyValidations = {
  company: {
    name: {
      required,
    },
    label: {
      required,
    },
    description: {
      required,
    },
    contact: {
      required,
    },
    address: {
      name: {
        required,
      },
      line_one: {
        required,
      },
      zipcode: {
        required,
        zipcode,
      },
      city: {
        required,
      },
    },
  },
};
