import {
  BaseService,
  DataResponse,
  Status,
  MessageResponse,
  MessageData,
} from '@/core/services/base.service';
import { Balance } from '@/models/balance';
import { Invoice } from '@/models/invoice';
import { Company } from '@/models/company';
import { Project } from '@/models/project';
import { Expense } from '@/models/expense';
import { ExpenseReference } from '@/models/reference';
import { TaxInfo } from '@/models/taxes';

export class InvoiceInfo {
  public invoice: Invoice;
  public company: Company;
  public project: Project;

  constructor(data: InvoiceInfo) {
    this.invoice = new Invoice(data.invoice);
    this.company = new Company(data.company);
    this.project = new Project(data.project);
  }
}

export class ExpenseInfo {
  public expense: Expense;
  public reference: ExpenseReference;

  constructor(data: ExpenseInfo) {
    this.expense = new Expense(data.expense);
    this.reference = data.reference;
  }
}

class BalanceService extends BaseService {
  private url: string = '/api/balance';

  constructor() {
    super();
  }

  public async get(type: string, date: string): Promise<DataResponse<Balance>> {
    const url = date ? `${this.url}/${type}/${date}` : `${this.url}`;
    const res = await this.$http.get<Balance>(url);
    return {
      status: res.status === Status.Ok,
      data: new Balance(res.data),
    };
  }

  public async getInvoiceInfo(
    number: string,
    _: string,
  ): Promise<DataResponse<InvoiceInfo>> {
    const url = `${this.url}/invoice/${number}`;
    const res = await this.$http.get<InvoiceInfo>(url);
    return {
      status: res.status === Status.Ok,
      data: new InvoiceInfo(res.data),
    };
  }
  public async getProjectInvoices(
    projectId: number,
  ): Promise<DataResponse<Invoice[]>> {
    const url = `${this.url}/invoice/project/${projectId}`;
    const res = await this.$http.get<Invoice[]>(url);
    return {
      status: res.status === Status.Ok,
      data: res.data.map((d) => new Invoice(d)),
    };
  }

  public async addInvoice(data: Invoice): Promise<DataResponse<Invoice>> {
    const url = `${this.url}/invoice`;
    const res = await this.$http.post<Invoice>(url, data);
    return {
      status: res.status === Status.Ok,
      data: new Invoice(res.data),
    };
  }

  public async updateInvoiceStatus(
    number: string,
    date: string,
  ): Promise<MessageResponse> {
    const url = `${this.url}/invoice/${number}/status/paid`;
    const res = await this.$http.put<MessageData>(url, { date });
    return {
      status: res.status === Status.Ok,
      message: res.data.message,
    };
  }

  public async getExpenseInfo(
    number: string,
    _: string,
  ): Promise<DataResponse<ExpenseInfo>> {
    const url = `${this.url}/expense/${number}`;
    const res = await this.$http.get<ExpenseInfo>(url);
    return {
      status: res.status === Status.Ok,
      data: new ExpenseInfo(res.data),
    };
  }

  public async addExpense(data: Expense): Promise<DataResponse<ExpenseInfo>> {
    const url = `${this.url}/expense`;
    const res = await this.$http.post<ExpenseInfo>(url, data);
    return {
      status: res.status === Status.Ok,
      data: new ExpenseInfo(res.data),
    };
  }

  public async createTaxInfo(data: TaxInfo): Promise<DataResponse<TaxInfo>> {
    const url = `${this.url}/taxes/${data.year}`;
    const res = await this.$http.post<TaxInfo>(url, data);
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }
  public async updateTaxInfo(data: TaxInfo): Promise<DataResponse<TaxInfo>> {
    const url = `${this.url}/taxes/${data.year}`;
    const res = await this.$http.put<TaxInfo>(url, data);
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }
}

export default new BalanceService();
