import {
  BaseService,
  DataResponse,
  Status,
} from '@/core/services/base.service';
import { Holidays } from '@/models/holidays';

class AdminService extends BaseService {
  private url = '/api/holidays';

  constructor() {
    super();
  }

  public async getHolidays(year: number): Promise<DataResponse<Holidays>> {
    const res = await this.$http.get<Holidays>(`${this.url}/${year}`);
    return {
      status: res.status === Status.Ok,
      data: new Holidays(res.data),
    };
  }

  public async storeHolidays(
    holidays: Holidays,
  ): Promise<DataResponse<Holidays>> {
    const res = await this.$http.post<Holidays>(
      `${this.url}/${holidays.year}`,
      holidays,
    );
    return {
      status: res.status === Status.Ok,
      data: new Holidays(res.data),
    };
  }
}

export default new AdminService();
