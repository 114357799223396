export class Address {
  public name: string;
  public line_one: string;
  public line_two: string;
  public zipcode: string;
  public city: string;
  public country: string;

  constructor(data: Address) {
    this.name = data.name;
    this.line_one = data.line_one;
    this.line_two = data.line_two;
    this.zipcode = data.zipcode;
    this.city = data.city;
    this.country = data.country;
  }
}
