import { TimesheetDay } from '@/models/timesheet-day';
import mitt from 'mitt';

export enum Event {
  HoursFocus = 'HoursFocus',
  HoursStore = 'HoursStore',
  HoursStored = 'HoursStored',
  HoursChanged = 'HoursChanged',
  HoursResetChanged = 'HoursResetChanged',
  TaxesUpdated = 'TaxesUpdated',
}
export type Events = {
  [Event.HoursFocus]: TimesheetDay | null;
  [Event.HoursStore]: Object;
  [Event.HoursStored]?: Object;
  [Event.HoursChanged]: TimesheetDay;
  [Event.HoursResetChanged]: TimesheetDay;
  [Event.TaxesUpdated]: Object;
};

const eventStore = mitt<Events>();

export const useEvents = () => {
  return eventStore;
};
