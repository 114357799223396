import {
  BaseService,
  DataResponse,
  Status,
} from '@/core/services/base.service';
import {
  Timesheet,
  TimesheetOverview,
  DayByDayOverview,
  DayOverview,
  TimesheetData,
} from '@/models/timesheet';
import { TimesheetDay, Hours } from '../models/timesheet-day';
import { Invoice } from '../models/invoice';

class TimesheetService extends BaseService {
  private url: string = '/api/timesheet';

  constructor() {
    super();
  }

  public async get(
    projectID: number,
    date: string,
  ): Promise<DataResponse<Timesheet>> {
    const url = date
      ? `${this.url}/project/${projectID}/month/${date}`
      : `${this.url}/project/${projectID}`;
    const res = await this.$http.get<TimesheetData>(url);
    return {
      status: res.status === Status.Ok,
      data: new Timesheet(res.data),
    };
  }

  public async getOverview(
    date: string,
  ): Promise<DataResponse<TimesheetOverview>> {
    const url = date
      ? `${this.url}/overview/month/${date}`
      : `${this.url}/overview`;
    const res = await this.$http.get<TimesheetOverview>(url);
    return {
      status: res.status === Status.Ok,
      data: new TimesheetOverview(res.data),
    };
  }

  public async getDayByDayOverview(
    date: string,
  ): Promise<DataResponse<DayByDayOverview>> {
    const url = date
      ? `${this.url}/overview/month/${date}/day-by-day`
      : `${this.url}/overview/day-by-day`;
    const res = await this.$http.get<DayByDayOverview>(url);
    return {
      status: res.status === Status.Ok,
      data: new DayByDayOverview(res.data),
    };
  }

  public async getDayOverview(
    date: string,
  ): Promise<DataResponse<DayOverview>> {
    const res = await this.$http.get<DayOverview>(
      `${this.url}/day/month/${date}`,
    );
    return {
      status: res.status === Status.Ok,
      data: new DayOverview(res.data),
    };
  }

  public async getProjectHours(
    projectId: number,
  ): Promise<DataResponse<{ hours: string }>> {
    const res = await this.$http.get<{ hours: string }>(
      `${this.url}/project/${projectId}/hours`,
    );
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async getTimesheetInvoices(
    projectID: number,
    date: string,
  ): Promise<DataResponse<Invoice[]>> {
    const res = await this.$http.get<Invoice[]>(
      `${this.url}/project/${projectID}/month/${date}/invoices`,
    );
    return {
      status: res.status === Status.Ok,
      data: res.data.map((i: Invoice) => new Invoice(i)),
    };
  }

  public async save(
    changes: Hours[] = [],
    removals: Hours[] = [],
  ): Promise<DataResponse<Hours[]>> {
    const res = await this.$http.post<Hours[]>(this.url, {
      changes,
      removals,
    });
    return {
      status: res.status === Status.Ok,
      data: res.data ? res.data : [],
    };
  }
}

export default new TimesheetService();
